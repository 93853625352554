<template>
  <div>
    <div class="container">
      <div class="row mb-2">
        <div class="col-3">
          <button class="btn btn-primary btn-sm" @click="backPage">
            {{ $t("back") }}
          </button>
        </div>
        <div class="col-3"></div>
        <div class="col-3"></div>
        <div class="col-3 text-end">
          <button class="btn btn-primary btn-sm" disabled>
            {{ $t("list_download") }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Lat/Long</th>
                      <th scope="col">Altitude</th>
                      <th scope="col">Speed<br>Heading<br>Satalites</th>
                      <th scope="col">Pressure</th>
                      <th scope="col">Temperature</th>
                      <th scope="col">Voltage</th>
                      <th scope="col">Report Date</th>


                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in list" :key="row.id">
                      <td>{{ row.id }}</td>
                      <td>{{ Number(row.latitude).toFixed(5) }},{{ Number(row.longtitute).toFixed(5) }}
                        <br>
 <div v-html="mapslink(row.latitude,row.longtitute)"></div>

                      </td>
                      <td>{{ Number(row.altitudeMetre).toFixed(2) }}<br>{{ Number(row.altitudeKm).toFixed(2) }}</td>
                      <td>{{ Number(row.gpsSpeed).toFixed(2) }}<br>{{ Number(row.gpsHeading).toFixed(2) }}<br>{{ row.gpsSatellites }}</td>
                      <td>{{ Number(row.pressure).toFixed(2) }}</td>
                      <td>{{ Number(row.temperature).toFixed(2) }}</td>
                      <td>{{ Number(row.voltage).toFixed(2) }}</td>
                      <td>{{ formatDate(row.reported_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FacilityUsageModal
      v-show="isModalVisible"
      @close="closeModal"
      :postData="editData"
    />
  </div>
</template>

<script>
import { getAll, remove } from "../service/facility-usage";
import FacilityUsageModal from "../components/FacilityUsageModal.vue";
import FacilityUsage from "../model/facility-usage";
import { mapState } from "vuex";

export default {
  name: "FacilityUsage",
  data() {
    return {
      editData: {},
      list: {},
      isModalVisible: false,
    };
  },
  components: {
    FacilityUsageModal,
  },
  computed: {
    ...mapState(["facilityUsageReload"]),
  },
  methods: {
    edit: function (id) {
      const select = this.list.find((l) => l.id == id);
      this.editData = new FacilityUsage(select.id,select.unit,select.start_date,select.end_date,select.usage,select.total,select.discount);
      this.isModalVisible = true;
    },
    remove: function (id) {
      const params = { id: this.$route.params.id, usage : { "id": id} };
      remove(params).then(() => {
        this.loadUsage();
      });
    },
    addFacility() {
      this.editData = new FacilityUsage();
      this.isModalVisible = true;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    backPage() {
      this.$router.go(-1)
    },
    loadUsage() {
      const params = { id: this.$route.params.id };
      getAll(params).then((res) => {
        this.list = res.data;
      });
    },
    remainingday: function (val1, val2) {
      const date1 = new Date(val1);
      const date2 = new Date(val2);
      const difference = date1.getTime() - date2.getTime();
      const days = Math.ceil(difference / (1000 * 3600 * 24));
      return days;
    },
     padTo2Digits(num) {
  return num.toString().padStart(2, '0');
},
formatDate(d) {
  let date = new Date(d);
  return (
    [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      this.padTo2Digits(date.getHours()),
      this.padTo2Digits(date.getMinutes()),
      this.padTo2Digits(date.getSeconds()),
    ].join(':')
  );
},
    mapslink: function (val1, val2) {
            return '<a href="https://www.google.com/maps?q='+val1+','+val2+'" target="_blank">Google Maps</a>';
    },
  },
  mounted() {
    this.loadUsage();
  },
  watch: {
    facilityUsageReload() {
      if (this.facilityUsageReload) {
        this.$store.state.facilityUsageReload = false;
        this.loadUsage();
      }
    },
  },
};
</script>
