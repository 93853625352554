<template>
  <div>
    <div class="container">
      <div class="row mb-2">
        <div class="col-3"></div>
        <div class="col-3"></div>
        <div class="col-6 text-end">
          <button class="btn btn-primary btn-sm me-2" disabled>
            {{ $t("facility.add") }}
          </button>
          <button class="btn btn-primary btn-sm me-2" disabled>
            {{ $t("facility.add_helium") }}
          </button>
          <button class="btn btn-primary btn-sm" disabled>
            {{ $t("list_download") }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
              <table class="table table-hover align-middle">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("facility.name") }}</th>
                    <th scope="col">{{ $t("facility.uuid") }}</th>
                    <th scope="col">Live JPG</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in list" :key="row.uuid">
                    <td>{{ row.name }}</td>
                    <td>{{ row.uuid}}</td>
                    <td></td>


                    <td class="text-end">
                      <div
                        class="btn-group  text-end"
                        role="group"
                      >
                        <button
                        @click="FicilityUsagePush(row.uuid)"
                          type="button"
                          class="btn btn-success btn-sm"
                        >
                          {{ $t("facility.energydata") }}
                        </button>
                        <button
                          type="button"
                          disabled
                          class="btn btn-outline-danger btn-sm"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          disabled
                          class="btn btn-outline-primary btn-sm"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FacilityModal
      v-show="isModalVisible"
      @close="closeModal"
      :postData="editData"
    />
  </div>
</template>

<script>
import { getAll, remove } from "../service/facility";
import FacilityModal from "../components/FacilityModal.vue";
import Facility from "../model/facility";
import { mapState } from "vuex";

export default {
  name: "Facilities",
  data() {
    return {
      editData: {},
      list: {},
      isModalVisible: false,
    };
  },
  components: {
    FacilityModal,
  },
  computed: {
    ...mapState(["facilityReload"]),
  },
  methods: {
    edit: function (id) {
      const select = this.list.find((l) => l.id == id);
      this.editData = new Facility(select.id, select.name, select.start_date, select.end_date, select.workers, select.special);
      this.isModalVisible = true;
    },
    remove: function (id) {
      const params = { "id": id };
      remove(params).then(() => {
        this.loadFacilities();
      });
    },
    addFacility() {
      this.editData = new Facility();
      this.isModalVisible = true;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    loadFacilities() {
      getAll(this.user).then((res) => {
        this.list = res.data;
      });
    },
    remainingday: function (val1, val2) {
      const date1 = new Date(val1);
      const date2 = new Date(val2);
      const difference = date1.getTime() - date2.getTime();
      const days = Math.ceil(difference / (1000 * 3600 * 24));
      return days;
    },
    FicilityUsagePush: function(id){
      this.$router.push("/devices/" + id)
    }
  },
  imgURL(id) {
  return ("https://server.camtracker.appcent.dev/static/" + id + "_1.jpg");
},
  mounted() {
    this.loadFacilities();
  },
  watch: {
    facilityReload() {
      if (this.facilityReload) {
        this.$store.state.facilityReload = false;
        this.loadFacilities();
      }
    },
  },
};
</script>
